import React from "react";
import { Route, Routes } from "react-router-dom";

import { Login } from "../components/pages";
import ProtectedRoute from "./ProtectedRoute";
import Middle from "../components/organisms/Middle";
import Modal from "../components/atoms/Modal";
import SideMenu from "../components/organisms/SideMenu";
import DpgLogin from "../components/pages/Login/dpgLogin";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/dpglogin" element={<DpgLogin />} />
            <Route
                path="/*"
                element={
                    <ProtectedRoute>
                        <SideMenu />
                        <Middle />
                        <Modal />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AppRouter;
