import React, { useState } from "react";
import "./_style.scss";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../../modules/actions/setUserInfo";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const userInfo = useSelector((status) => status.userInfo);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showSettings, setShowSettings] = useState(false);

    const logout = () => {
        dispatch(
            setUserInfo({
                id: undefined,
                name: undefined,
                accessToken: null,
                refreshToken: null,
            })
        );

        // 로컬 스토리지 삭제
        localStorage.removeItem("lloydk_Sass_accessToken");
        localStorage.removeItem("lloydk_Sass_refreshToken");
        navigate("/login");
    };

    return (
        <div
            className="Header"
            style={{ display: userInfo.name !== undefined ? "" : "none" }}
        >
            <img className="logo_img" src="/images/logo-inverse.png" alt="" />
            <div className="login_info">
                <div className="user_img">
                    <i className="ri-user-line"></i>
                </div>
                <div className="user_info">
                    <div className="user_name">
                        {userInfo.name ? userInfo.name : "undefined"}
                    </div>
                    <div className="notice">환영합니다.</div>
                    <div>
                        <i
                            className="ri-settings-2-fill settingsModal"
                            onClick={() => setShowSettings(!showSettings)}
                        />
                    </div>
                    {showSettings && (
                        <ul className="settings_list">
                            <li
                                className="settings_item"
                                onClick={() => logout()}
                            >
                                로그아웃
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
