export const isActiveModal = () => ({
    type: "isActiveModal",
});

export const isActiveModalTrue = () => ({
    type: "isActiveModalTrue",
});

export const setModal = (modalComponent) => ({
    type: "setModal",
    payload: modalComponent,
});
