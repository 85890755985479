import React from "react";

const Profile = () => {
    return (
        <div className="profile">
            <img src="/images/logo-inverse.png" />
            <br />
        </div>
    );
};

export default Profile;
