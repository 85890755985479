import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isActiveModal, setModal } from "../../../modules/actions/modalAction";
import UserListTable from "../../molecules/user/userListTable";
import UserCreateModal from "../../molecules/user/userCreateModal";
import axiosInstance from "../../../modules/util/axiosInstance";

const UserList = () => {
    const dispatch = useDispatch();

    const modal = useSelector((status) => status.isActiveModal);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [userList, setUserList] = useState([]);

    // 유저정보 가져오기
    useEffect(() => {
        try {
            axiosInstance.get(`/api/member?page=${page}&size=20`).then((e) => {
                const responseData = e.data.data;
                setUserList(responseData?.itemList);
                setTotalPage(responseData?.total);
            });
        } catch (e) {}
    }, [modal, page]);

    const openModal = () => {
        dispatch(
            setModal({
                title: "유저 생성",
                size: {
                    width: 30,
                    height: 50,
                },
                component: <UserCreateModal User={{}} update={false} />,
            })
        );
        dispatch(isActiveModal());
    };

    return (
        <div className="userList">
            <h2>UserList</h2>
            <div className="addUser">
                <i className="ri-user-add-line" onClick={() => openModal()}></i>
            </div>
            <UserListTable
                userList={userList}
                page={page}
                setPage={setPage}
                totalPage={totalPage}
            />
        </div>
    );
};

export default UserList;
