import React, { useState } from "react";
import ButtonCheck from "../../../../../atoms/Button/Button.check";
import { useDispatch, useSelector } from "react-redux";

const ChatSetting = ({ settingModal, setSettingModal }) => {
    const chatSetting = useSelector((state) => state.chatSetting);

    return (
        <div
            className="chat-settings"
            tabIndex="0"
            onBlur={(e) => {
                if (e.relatedTarget === null) setSettingModal(false);
            }}
            style={settingModal ? {} : { display: "none" }}
        >
            <div className="title">
                <i className="ri-settings-2-fill"> </i>
                설정
                <i
                    className="ri-close-line"
                    onClick={() => setSettingModal(!setSettingModal)}
                />
            </div>
            <div className="sub_title">
                <i className="ri-filter-fill" />
                답변 고도화 기법
            </div>
            <hr />
            <div className="llm-select">
                <div>Agent</div>
                <div>{chatSetting.agentName}</div>
            </div>
            <div className="llm-select">
                <div>DSP</div>
                <ButtonCheck
                    list={[
                        { label: "on", value: "true" },
                        {
                            label: "off",
                            value: "false",
                        },
                    ]}
                    checked={chatSetting.dsp}
                />
            </div>
            <div className="llm-select">
                <div>HyDE</div>
                <ButtonCheck
                    list={[
                        { label: "on", value: "true" },
                        {
                            label: "off",
                            value: "false",
                        },
                    ]}
                    checked={chatSetting.hyde}
                />
            </div>
            <div className="llm-select">
                <div>CoT</div>
                <ButtonCheck
                    list={[
                        { label: "on", value: "true" },
                        {
                            label: "off",
                            value: "false",
                        },
                    ]}
                    checked={chatSetting.cot}
                />
            </div>
            <div className="llm-select">
                <div>refine</div>
                <ButtonCheck
                    list={[
                        { label: "on", value: "true" },
                        {
                            label: "off",
                            value: "false",
                        },
                    ]}
                    checked={chatSetting.refine}
                />
            </div>
            <div className="sub_title">
                <i className="ri-brain-line" />
                Model 선택
            </div>
            <hr />
            <div className="model-select">
                <div className="model-btn">
                    <div
                        style={
                            chatSetting.model === "gpt-4o"
                                ? { backgroundColor: "#007bff", color: "white" }
                                : {}
                        }
                    >
                        GPT-4o
                    </div>
                </div>
                <div className="model-btn">
                    <div
                        style={
                            chatSetting.model === "claude"
                                ? { backgroundColor: "#007bff", color: "white" }
                                : {}
                        }
                    >
                        Claude 3 Haiku
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatSetting;
