import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import axios from "axios";
import { setUserInfo } from "../../../modules/actions/setUserInfo";
import { useDispatch } from "react-redux";

const DpgLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 이미 가입한 유저일 시 : 메인 페이지로 이동
    const handleHome = () => {
        navigate("/home");
        window.location.reload();
    };

    // 처음 가입한 유저일 시 : 닉네임 설정 페이지로 이동
    const handleNickName = () => {
        navigate("/nickname");
        window.location.reload();
    };

    // 현재 url에서 code 부분 추출
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const session_state = params.get("session_state");

    const handleLoginPost = async (code) => {
        const data = new URLSearchParams();
        data.append("client_id", "lloydk");
        data.append("client_secret", "N9DonqIPqFP4lz2wa0LzIUlR5OTxMl9R");
        data.append("code", code);
        data.append("grant_type", "authorization_code");
        data.append("redirect_uri", "http://localhost:8080/dpglogin");
        data.append("scope", "openid");

        try {
            await axios
                .post(
                    "/api/auth/login",
                    {
                        code: code,
                        session_state: session_state,
                        sso: "dpg",
                    },
                    {
                        headers: {
                            accept: "*/*",
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((e) => {
                    dispatch(
                        setUserInfo({
                            name: "",
                            id: "",
                            accessToken: e.data.accessToken,
                            refreshToken: e.data.refreshToken,
                        })
                    );

                    // 로컬 스토리지에 저장
                    localStorage.setItem(
                        "lloydk_Sass_accessToken",
                        e.data.accessToken
                    );
                    localStorage.setItem(
                        "lloydk_Sass_refreshToken",
                        e.data.refreshToken
                    );

                    window.location.href = "/";
                });
        } catch (error) {
            window.location.href = "/login";
        }
    };

    useEffect(() => {
        if (code) {
            handleLoginPost(code);
        }
    }, [code, navigate]);

    return <></>;
};

export default DpgLogin;
