import React from "react";
import moment from "moment";
import MarkdownPreview from "@uiw/react-markdown-preview";
import "./_style.scss";

const UserMessage = ({ message }) => {
    return (
        <div className="user-message">
            <div className="user-time">
                {moment(message.createDate).format("HH:mm")}
            </div>
            <div className="user-text">
                <MarkdownPreview
                    source={message.content}
                    style={{ padding: 16 }}
                ></MarkdownPreview>
            </div>
        </div>
    );
};

export default UserMessage;
