import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import "./_style.scss";

const BotMessage = ({ message, loading }) => {
    return (
        <div className="ai-message">
            <img src="/favicon.png" alt="" />

            <div className="ai-text">
                <div className="ai-text-container">
                    <MarkdownPreview
                        source={message.content}
                        style={{ padding: 16, display: "inline-block" }}
                    />
                    {loading && (
                        <div className="loading-overlay">
                            <ClipLoader size={16} color={"#36D7B7"} />
                        </div>
                    )}
                </div>
            </div>

            <div className="ai-time">
                {moment(message.createDate).format("HH:mm")}
            </div>

            <div className="break" />

            <div className="ai-info">
                {message.model ? (
                    <div>
                        {message.model === "gpt-4o"
                            ? "GPT-4o"
                            : "Claude 3 Haiku"}
                    </div>
                ) : (
                    ""
                )}
                {message.cot === "true" ? <div>CoT</div> : ""}
                {message.hyde === "true" ? <div>HyDE</div> : ""}
                {message.refine === "true" ? <div>refine</div> : ""}
                {message.dsp === "true" ? <div>DSP</div> : ""}
            </div>
        </div>
    );
};

export default BotMessage;
