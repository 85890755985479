import React from "react";
import Profile from "../../molecules/setting/Profile";
import ApiKey from "../../molecules/setting/ApiKey";
import Agent from "../../molecules/setting/Agent";

const Setting = () => {
    return (
        <div className="setting" style={{ width: "100%", height: "100%" }}>
            <Profile />
            <Agent />
        </div>
    );
};

export default Setting;
