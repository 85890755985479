import React from "react";
import HomeChart from "../../molecules/Home/Chart";

const Home = () => {
    return (
        <div className="Home">
            <HomeChart />
        </div>
    );
};

export default Home;
