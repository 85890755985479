import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./_style.scss";

import ChatBottom from "./ChatBottom";
import ChatMessage from "./ChatMessage";
import { setChatToken } from "../../../../modules/actions/setChatToken";

const ChatContent = ({ list, setList }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const chatToken = useSelector((state) => state.chatToken);
    const chatSetting = useSelector((state) => state.chatSetting);

    const defaultAgentId = "40e1e4fc-403a-4ff2-bf22-ea71ece910c1";
    const agentId = useSelector((state) => state.agentId) || defaultAgentId;

    // 로딩 상태 추가
    const [loading, setLoading] = useState(false);

    const sendMessage = async (userText) => {
        const messageList = list;
        const setMessageList = setList;

        let botMessage = "";
        let chatInfo = "";
        const sendText = userText || "";

        if (sendText.trim() === "") return;

        const newMessageList = [
            ...messageList,
            { sender: "user", content: sendText },
        ];
        setMessageList(newMessageList);

        // 로딩 상태 활성화
        setLoading(true);

        // 생성중 답변
        setMessageList([
            ...newMessageList,
            { sender: "assistant", content: "답변 생성 중입니다..." },
        ]);

        const writer = new WritableStream({
            write(chunk) {
                // message 가져오기
                const getMessage = chunk
                    .split("\n")
                    .map((data) => {
                        if (data.trim() !== "") {
                            const match = data.match(/"message":"(.*?)"/g);
                            return match
                                ? match[0].replace(/"message":"(.*?)"/, "$1")
                                : null;
                        }
                    })
                    .join("")
                    .replaceAll("\\n", "\n");

                chatInfo = chunk;

                botMessage = botMessage + getMessage;
                setMessageList([
                    ...newMessageList,
                    {
                        sender: "assistant",
                        content: botMessage,
                        cot: "false",
                        hyde: "false",
                        refine: "false",
                        dsp: "false",
                        model: "",
                    },
                ]);
            },

            close() {
                //config 추가
                const chunkString = chatInfo.split("\n").filter(Boolean);
                const chatInfoData = JSON.parse(
                    chunkString[chunkString.length - 1].replace("data:", "")
                );
                dispatch(setChatToken(chatInfoData.token));

                // 로딩 상태 비활성화
                setLoading(false);

                setMessageList([
                    ...newMessageList,
                    {
                        sender: "assistant",
                        content: botMessage,
                        cot: chatInfoData.cot.toString() || "false",
                        hyde: chatInfoData.hyde.toString() || "false",
                        refine: chatInfoData.refine.toString() || "false",
                        dsp: chatInfoData.dsp.toString() || "false",
                        model: chatInfoData.model,
                    },
                ]);
            },
        });

        await fetch("/api/chat/ai/generate/stream", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.accessToken}`,
                Accept: "*/*",
            },
            body: JSON.stringify({
                userMessage: sendText,
                token: chatToken,
                model: chatSetting.model,
                hyde: chatSetting.hyde,
                cot: chatSetting.cot,
                refine: chatSetting.refine,
                dsp: chatSetting.dsp,
                agentId: agentId,
            }),
        }).then((res) => {
            res.body.pipeThrough(new TextDecoderStream()).pipeTo(writer);
        });
    };

    return (
        <div className="chat-content">
            <ChatMessage messageList={list} loading={loading} />
            <ChatBottom sendMessage={sendMessage} />
        </div>
    );
};

export default ChatContent;
