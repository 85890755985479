import React from "react";
import "./_style.scss";

const ButtonCheck = ({ list, checked, click }) => {
    return (
        <div className="check">
            {list.map((data, idx) => {
                return checked === data.value ? (
                    <button
                        key={"checkBtn_" + idx}
                        value={data.value}
                        className="check"
                    >
                        {data.label}
                    </button>
                ) : (
                    <button
                        key={"checkBtn_" + idx}
                        value={data.value}
                        onClick={click}
                    >
                        {data.label}
                    </button>
                );
            })}
        </div>
    );
};

export default ButtonCheck;
