const initialState = {
    apikey: undefined,
    chatSetting: {
        agentName: "Default",
        cot: "true",
        hyde: "true",
        refine: "true",
        dsp: "auto",
        model: "gpt-4o",
    },
    // chatToken
    chatToken: "",
    agentId: "",
    // 사이드 메뉴 접히는 부분
    isActiveSideMenu: true,
    // 모달 활성화
    isActiveModal: false,
    // 모달 Component 구성
    modalComponent: {
        title: "",
        size: {},
        component: undefined,
    },
    // 로그인 유저정보
    userInfo: {
        name: undefined,
        id: undefined,
        accessToken: localStorage.getItem("lloydk_Sass_accessToken"),
        refreshToken: localStorage.getItem("lloydk_Sass_refreshToken"),
    },
    location: "/",
};

const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "setApiKey":
            return { ...state, apikey: action.payload };
        case "setChatSetting":
            return { ...state, chatSetting: action.payload };
        case "isActiveSideMenu":
            return { ...state, isActiveSideMenu: !state.isActiveSideMenu };
        case "isActiveModalTrue":
            return { ...state, isActiveModal: true };
        case "isActiveModal":
            return state.isActiveModal
                ? {
                      ...state,
                      isActiveModal: !state.isActiveModal,
                      modalComponent: {
                          title: "",
                          size: {},
                          component: undefined,
                      },
                  }
                : { ...state, isActiveModal: !state.isActiveModal };
        case "setModal":
            return { ...state, modalComponent: action.payload };
        case "setUserInfo":
            return { ...state, userInfo: action.payload };
        case "setChatToken":
            return { ...state, chatToken: action.payload };
        case "setLocation":
            return { ...state, location: action.payload };
        case "setAgentId":
            return { ...state, agentId: action.payload };
        default:
            return state;
    }
};

export default counterReducer;
