import React, { useEffect, useState } from "react";
import { setChatToken } from "../../../../modules/actions/setChatToken";

import { useDispatch, useSelector } from "react-redux";
import "./_style.scss";
import AgentSelectModal from "./AgentSelectModal/index";
import { setAgentId } from "../../../../modules/actions/setAgentId";
import axiosInstance from "../../../../modules/util/axiosInstance";
import { setChatSetting } from "../../../../modules/actions/setChatSetting";

const getDateLabel = (conversationDate) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const firstDayOfLastMonth = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        1
    );

    // "오늘" 먼저 확인
    if (conversationDate >= today) {
        return "오늘";
    }
    // 그 다음 "어제" 확인
    if (conversationDate >= yesterday && conversationDate < today) {
        return "어제";
    }
    // "이번 달"
    if (conversationDate >= firstDayOfThisMonth) {
        return "이번 달";
    }
    // "지난 달"
    if (
        conversationDate >= firstDayOfLastMonth &&
        conversationDate < firstDayOfThisMonth
    ) {
        return "지난 달";
    }
    // "작년"인 경우
    if (conversationDate.getFullYear() < now.getFullYear()) {
        return `작년 ${conversationDate.getMonth() + 1}월`;
    }
    // 몇 개월 전인 경우
    const monthsAgo =
        now.getMonth() -
        conversationDate.getMonth() +
        12 * (now.getFullYear() - conversationDate.getFullYear());
    return `${monthsAgo}개월 전`;
};

const categorizeConversations = (conversations) => {
    const categorizedConversations = {};

    conversations.forEach((conversation) => {
        const conversationDate = new Date(conversation.createDate);
        const label = getDateLabel(conversationDate);

        // Initialize the category if it doesn't exist
        if (!categorizedConversations[label]) {
            categorizedConversations[label] = [];
        }

        // Push the conversation into the correct date category
        categorizedConversations[label].push(conversation);
    });
    const sortedCategories = Object.keys(categorizedConversations).sort(
        (a, b) => {
            if (a === "오늘") return -1;
            if (b === "오늘") return 1;
            if (a === "어제") return -1;
            if (b === "어제") return 1;
            return 0; // 기본적으로 다른 날짜는 순서대로 표시
        }
    );
    const sortedConversations = {};
    sortedCategories.forEach((key) => {
        sortedConversations[key] = categorizedConversations[key];
    });

    return sortedConversations;
};

const ChatList = ({ list }) => {
    const chatList = categorizeConversations(list);
    const dispatch = useDispatch();
    const chatToken = useSelector((status) => status.chatToken);
    const chatSetting = useSelector((state) => state.chatSetting);

    const [isAgentModalVisible, setAgentModalVisible] = useState(false);

    const handleNewAgentClick = () => {
        setAgentModalVisible(true);
    };

    const handleCloseModal = () => {
        setAgentModalVisible(false);
    };

    const setAgentInfo = async (agentId) => {
        try {
            const response = await axiosInstance.get(
                `/api/agent/agentInfo/${agentId}`
            );
            if (response.data.msg === "success") {
                console.log("agentInfo", response.data.data.cot);
                dispatch(
                    setChatSetting({
                        ...chatSetting,
                        agentName: response.data.data.agentName,
                        dsp: response.data.data.dsp,
                        cot: response.data.data.cot,
                        refine: response.data.data.refine,
                        hyde: response.data.data.hyde,
                        model: response.data.data.model,
                    })
                );
            }
        } catch (error) {
            console.error("Error : " + error);
        }
    };

    return (
        <div className="chat-list-div">
            <div>
                <button
                    className="new_chat_token_btn"
                    onClick={() => {
                        dispatch(setChatToken(""));
                        handleNewAgentClick();
                    }}
                >
                    새로운 에이전트 생성
                </button>
            </div>
            <div className="chat-list-title">
                <ul>
                    {Object.keys(chatList).map((label) => (
                        <div key={label}>
                            <h3>{label}</h3>
                            {chatList[label].map((conversation) => (
                                <li
                                    key={conversation.conversationId}
                                    className={
                                        chatToken ===
                                        conversation.conversationId
                                            ? "selected"
                                            : ""
                                    }
                                    onClick={() => {
                                        dispatch(
                                            setChatToken(
                                                conversation.conversationId
                                            )
                                        );
                                        dispatch(
                                            setAgentId(conversation.agentId)
                                        );
                                        setAgentInfo(conversation.agentId);
                                    }}
                                >
                                    {conversation.title || "Untitled"}
                                </li>
                            ))}
                        </div>
                    ))}
                </ul>
            </div>
            {isAgentModalVisible && (
                <AgentSelectModal onClose={handleCloseModal} />
            )}
        </div>
    );
};

export default ChatList;
