const AgentCreateModal = ({ onClose, data }) => {
    return (
        <div className="agent-select-modal-overlay">
            <form className="agent-select-modal">
                <button onClick={onClose} className="close-button">
                    X
                </button>
                <h2>Desription</h2>
                <div>{data.description}</div>
                <h2>SYSTEM PROMPT</h2>
                <div>{data.systemPrompt}</div>
                <h2>USER PROMPT</h2>
                <div>{data.userPrompt}</div>
                <h2>적용 프롬프트 기법</h2>
                <div>
                    {data.cot === "true" && (
                        <div style={{ display: "inline" }}>COT</div>
                    )}
                    {data.hyde === "true" && (
                        <div style={{ display: "inline" }}>, HYDE</div>
                    )}
                    {data.dsp === "true" && (
                        <div style={{ display: "inline" }}>, DSP</div>
                    )}
                    {data.refine === "true" && (
                        <div style={{ display: "inline" }}>, REFINE</div>
                    )}
                </div>
                <h2>적용 모델</h2>
                <div>{data.model}</div>
            </form>
        </div>
    );
};

export default AgentCreateModal;
