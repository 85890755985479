import React from "react";

const Input = (props) => {
  return (
    <div className="inputBox">
      <input
        onKeyUp={props.onKeyup}
        ref={(e) => {
          props.inputRef.current = e;
        }}
        onChange={props.onChange}
        type={props.type}
        required
      />
      <label>{props.label}</label>
      <span></span>
    </div>
  );
};

export default Input;
