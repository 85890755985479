import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../modules/util/axiosInstance";
import AgentListTable from "../../../molecules/Agent/AgentListTable";
import AgentCreateModal from "../../../molecules/Agent/AgentCreateModal";
import "./_style.scss";

const Agent = () => {
    const [agents, setAgentsList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [isAgentModalVisible, setAgentModalVisible] = useState(false);

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        try {
            axiosInstance
                .get(`/api/agent/agentlist?page=${page}&size=5`)
                .then((e) => {
                    const responseData = e.data.data;
                    setAgentsList(responseData?.itemList);
                    setTotalPage(responseData?.total);
                });
        } catch (e) {}
    }, [page]);

    const handleNewAgentClick = () => {
        setAgentModalVisible(true);
    };

    const handleCloseModal = () => {
        setAgentModalVisible(false);
    };

    return (
        <div className="agent-container">
            <div className="table-button-container">
                <div className="agent-create-button-container">
                    <button
                        className="agent-create-button"
                        onClick={() => {
                            handleNewAgentClick();
                        }}
                    >
                        Agent 생성
                    </button>
                </div>
                <div>
                    <AgentListTable
                        agentList={agents}
                        page={page}
                        setPage={setPage}
                        totalPage={totalPage}
                        usingSelect={false}
                    />
                </div>
                <div>
                    {isAgentModalVisible && (
                        <AgentCreateModal onClose={handleCloseModal} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Agent;
